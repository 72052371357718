import { brand } from "./common/brand"
import { footer } from "./common/footer"
import { headerV2 } from "./common/headerV2"

export default {
  title: "Free CV Review",
  slug: "cv-review",
  seo: {
    pageTitle: "Vérificateur de CV - Analyse et évaluation gratuits de CV en ligne | TopCV",
    description: "Faites analyser et évaluer votre CV par un expert gratuitement !",
    openGraphTitle: null,
    openGraphDescription: null,
    openGraphImage: null,
    noIndex: false,
    doNotDeployToProduction: false,
    pageType: null,
    canonicalUrl: null,
  },
  ...headerV2,
  ...brand,
  ...footer,
}
