exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-revision-cv-tsx": () => import("./../../../src/pages/revision-cv.tsx" /* webpackChunkName: "component---src-pages-revision-cv-tsx" */),
  "component---src-templates-account-history-tsx": () => import("./../../../src/templates/accountHistory.tsx" /* webpackChunkName: "component---src-templates-account-history-tsx" */),
  "component---src-templates-critique-tsx": () => import("./../../../src/templates/critique.tsx" /* webpackChunkName: "component---src-templates-critique-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-landing-page-v-2-tsx": () => import("./../../../src/templates/landingPageV2.tsx" /* webpackChunkName: "component---src-templates-landing-page-v-2-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/productPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */)
}

