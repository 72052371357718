import { brand } from "./common/brand"
import { footer } from "./common/footer"
import { headerV2 } from "./common/headerV2"

export default {
  title: "New Homepage",
  slug: "index",
  seo: {
    pageTitle:
      "Services professionnels de rédaction de CV, de lettres de motivation et de profils LinkedIn - Services de rédaction de CV et de profil LinkedIn",
    description: "Que vous recherchiez des services de rédaction de CV pour décrocher",
    openGraphTitle: null,
    openGraphDescription: null,
    openGraphImage: null,
    noIndex: false,
    doNotDeployToProduction: false,
    pageType: null,
    canonicalUrl: null,
  },
  ...headerV2,
  ...brand,
  ...footer,
}
